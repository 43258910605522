import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShowTimeIteText from "./ShowTimeItemText";
import ShowTimeItemCover from "./ShowTimteItemCover";
import ShowTimeItemImage from "./ShowTimeItemImage";
import { ArrowTStyled } from "../../svg";
import ShareButton from "./ShareButton";

const WrapperStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const SliderStyled = styled.div`
    display: grid;
    position: relative;
    height: 100%;
    transition: right 0.5s cubic-bezier(0.37, 0, 0.63, 1) 0s, left 0.5s cubic-bezier(0.37, 0, 0.63, 1) 0s;
`;

const ArrowsStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    width: 100%;
    position: absolute;
    bottom: 0;
`;

const ArrowItemWrapperStyled = styled.div`
    cursor: pointer;
`;

const ShareWrapperStyled = styled.div`
    position: relative;
    display: grid;
    align-content: end;
    display: none;

    @media (max-width: 767px) {
        align-content: inherit;
    }
`;

const ArrowItemStyled = styled.div`
    font-size: 60px;
    z-index: 22;

    path {
        fill: rgb(255, 169, 39);
    }

    &.rtl {
        justify-self: end;
    }

    &.next {
        justify-self: end;

        &.rtl {
            justify-self: start;
        }

        svg {
            transform: rotate(180deg);
        }
    }
`;

const ShowTimeList = ({ pages, fontSize, lang, bookData, frameWidth, frameHeight }) => {
    const [currentPage, s_currentPage] = useState(0);
    const [startX, setStartX] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [difference, s_difference] = useState(0);
    const isRtl = lang === "he";

    useEffect(() => {
        if (!isDragging) {
            if (difference < 0) { // swipe left
                if (isRtl) {
                    if (isNextShow) {
                        s_currentPage(currentPage + 1);
                    }
                } else {
                    if (isPrevShow) {
                        s_currentPage(currentPage - 1);
                    }
                }
            } else if (difference > 0) { // swipe right
                if (isRtl) {
                    if (isPrevShow) {
                        s_currentPage(currentPage - 1);
                    }
                } else {
                    if (isNextShow) {
                        s_currentPage(currentPage + 1);
                    }
                }
            }
            s_difference(0);
        }
    }, [difference, isDragging]);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setIsDragging(true);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;

        const currentX = e.touches[0].clientX;
        s_difference(startX - currentX);
        setStartX(currentX);
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    const normalPages = [];
    pages.forEach(page => {
        if (page.pageIndex === -1) {
            normalPages.push({
                type: "COVER",
                image: page.selectedImageUrl,
                _id: page._id
            });
        }
        if (page.pageIndex !== -1) {
            normalPages.push({
                type: "TEXT",
                text: page.text,
                _id: page._id
            });
            normalPages.push({
                type: "IMAGE",
                image: page.selectedImageUrl,
                _id: page._id
            });
        }
    });

    const isNextShow = currentPage + 1 < normalPages.length;
    const isPrevShow = currentPage > 0;

    const handleKeyDown = (e) => {
        switch (e.key) {
            case "ArrowLeft":
                if (isRtl) {
                    if (isNextShow) {
                        s_currentPage(currentPage + 1);
                    }
                } else {
                    if (isPrevShow) {
                        s_currentPage(currentPage - 1);
                    }
                }
                break;
            case "ArrowRight":
                if (isRtl) {
                    if (isPrevShow) {
                        s_currentPage(currentPage - 1);
                    }
                } else {
                    if (isNextShow) {
                        s_currentPage(currentPage + 1);
                    }
                }
                break;
            default:
                // Handle other keys if needed
                break;
        }
    };

    const keyForSlider = isRtl ? "left" : "right";

    let svgWidth = 100;
    let svgHeight = 100;
    if (frameWidth < 900) {
        svgWidth = 60;
        svgHeight = 60;
    }
    if (frameHeight < 800) {
        svgWidth = 60;
        svgHeight = 60;
    }

    return (
        <WrapperStyled
            tabIndex="0"
            onKeyDown={ handleKeyDown }
        >
            <ArrowsStyled>
                <ArrowItemStyled
                    className={ isRtl ? "next rtl" : "" }
                >
                    { isPrevShow && (
                        <ArrowItemWrapperStyled
                            onClick={ () => {
                                s_currentPage(currentPage - 1);
                            } }
                        >
                            <ArrowTStyled
                                width={ svgWidth }
                                height={ svgHeight }
                            />
                        </ArrowItemWrapperStyled>
                    ) }
                </ArrowItemStyled>
                <ShareWrapperStyled>
                    <ShareButton lang={ lang } currentPage={ currentPage }/>
                </ShareWrapperStyled>
                <ArrowItemStyled
                    className={ isRtl ? "rtl" : "next" }
                >
                    { isNextShow && (
                        <ArrowItemWrapperStyled
                            onClick={ () => {
                                s_currentPage(currentPage + 1);
                            } }
                        >
                            <ArrowTStyled
                                width={ svgWidth }
                                height={ svgHeight }
                            />
                        </ArrowItemWrapperStyled>
                    ) }
                </ArrowItemStyled>
            </ArrowsStyled>
            <SliderStyled
                onTouchStart={ handleTouchStart }
                onTouchMove={ handleTouchMove }
                onTouchEnd={ handleTouchEnd }
                style={ {
                    width: `${ 100 * normalPages.length }%`,
                    [keyForSlider]: `${ 100 * currentPage }%`,
                    gridTemplateColumns: `repeat(${ normalPages.length }, 1fr)`
                } }
            >
                { normalPages.map((page, idx) => {
                    if (page.type === "COVER") {
                        return (
                            <ShowTimeItemCover
                                frameHeight={ frameHeight }
                                frameWidth={ frameWidth }
                                fontSize={ fontSize }
                                key={ page._id + idx }
                                page={ page }
                                title={ bookData.title }
                                coverDesign={ bookData.coverDesign }
                                coverData={ bookData.coverData }
                            />
                        );
                    }
                    if (page.type === "TEXT") {
                        return (
                            <ShowTimeIteText
                                fontSize={ fontSize }
                                key={ page._id + idx }
                                page={ page }
                            />
                        );
                    }
                    if (page.type === "IMAGE") {
                        return (
                            <ShowTimeItemImage
                                key={ page._id + idx }
                                page={ page }
                            />
                        );
                    }
                }) }
            </SliderStyled>
        </WrapperStyled>
    );
};

export default ShowTimeList;
